import React from 'react'
import PropTypes from 'prop-types'

import ProgramTemplate from '../../../components/templates/ProgramTemplate'

const ProgramPage = ({ id, location }) => <ProgramTemplate id={id} location={location} />

ProgramPage.propTypes = {
  id: PropTypes.string,
  location: PropTypes.object
}

export default ProgramPage
